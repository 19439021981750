import { render, staticRenderFns } from "./FaqSettings.vue?vue&type=template&id=354084c7&scoped=true&"
import script from "./FaqSettings.vue?vue&type=script&lang=js&"
export * from "./FaqSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354084c7",
  null
  
)

export default component.exports